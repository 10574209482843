import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/ZPage";
import Home from "./screens/ZHome";
import Buy from "./screens/ZHomeBuy";
import Sell from "./screens/ZHomeSell";
import Auction from "./screens/ZHomeAuction";
import Faq from "./screens/ZFaq";
import Contact from "./screens/ZContact";
import ConnectWallet from "./screens/ZConnectWallet";
import About from "./screens/ZAbout";
import Customize from "./screens/ZCustomize";



import UploadVariants from "./screens/UploadVariants";
import UploadDetails from "./screens/UploadDetails";
import Activity from "./screens/Activity";
import Search01 from "./screens/Search01";
import Search02 from "./screens/Search02";
import Profile from "./screens/Profile";
import ProfileEdit from "./screens/ProfileEdit";
import Item from "./screens/Item";
import PageList from "./screens/PageList";
import Control from "./components/ZControl";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => (<Page><Home /></Page>)} />
        <Route exact path="/buy" render={() => (<Page><Home /></Page>)} />
        <Route exact path="/sell" render={() => (<Page><Home /></Page>)} />
        <Route exact path="/auction" render={() => (<Page><Home /></Page>)} />
        <Route exact path="/faq" render={() => (<Page><Faq /></Page>)}/>
        <Route exact path="/contact" render={() => (<Page><Contact /></Page>)}/>
        <Route exact path="/customize" render={() => (<Page><Customize /></Page>)}/>

        <Route exact path="/about" render={() => (<Page><About /></Page>)}/>

        <Route exact path="/connect-wallet" render={() => (<Page><ConnectWallet /></Page>)}/>


        {/* 
        <Route exact path="/upload-variants" render={() => (<Page><UploadVariants /></Page>)}/>
        <Route exact path="/upload-details" render={() => (<Page><UploadDetails /></Page>)}/>
        <Route exact path="/connect-wallet" render={() => (<Page><ConnectWallet /></Page>)}/>
        <Route exact path="/activity" render={() => (<Page><Activity /></Page>)}/>
        <Route exact path="/search01" render={() => (<Page><Search01 /></Page>)}/>
        <Route exact path="/search02" render={() => (<Page><Search02 /></Page>)}/>
        <Route exact path="/profile" render={() => (<Page><Profile /></Page>)}/>
        <Route exact path="/profile-edit" render={() => (<Page><ProfileEdit /></Page>)}/>
        <Route exact path="/item" render={() => (<Page><Item /></Page>)}/>
        <Route exact path="/pagelist" render={() => (<Page><PageList /></Page>)}/>
        */}
      </Switch>
    </Router>
  );
}

export default App;
